import axios from 'axios'
// import config from '@/config'
import Vue from 'vue'
const _this=new Vue()
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API // api 的 base_url
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    return Promise.reject(error)
  }
)

export default service

