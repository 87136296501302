<template>
  <div class="gamenews">
    <div class="gamenewstop">
      <wc-swiper v-if="bannerlist.length">
        <wc-slide v-for="(item,index) of bannerlist" :key="index">
            <img style="width: 100%;height: 375px;" :src="item" alt="">
        </wc-slide>
      </wc-swiper>
      <div class="backtitle">
        <div v-if="showtitle">
          <div style="height: 44px;background: #F0F2F5;"></div>
          <div class="backinfo">
            <div class="back" @click="backclick">
              <van-icon class="icon" name="arrow-left" />
            </div>
            <div class="backtxt">{{ newsitem.title }}</div>
          </div>
        </div>
        <div v-else class="back" @click="backclick">
          <van-icon class="icon" name="arrow-left" />
        </div>
      </div>
    </div>
    <div class="gamenewsmain">
      <div class="title">{{ newsitem.title }}</div>
      <div class="datetime">{{ newsitem.createtime }}</div>
      <div class="relationgame" v-if="newsitem.game_id">
        <div class="left">
          <img class="gamelogourl" :src="gameinfo.logo_url" alt="">
          <div>
            <div class="playtxt">登陆玩</div>
            <div class="nametxt">{{ gameinfo.name }}</div>
          </div>
        </div>
        <span class="see">查看</span>
      </div>
      <div class="content" v-html="newsitem.content"></div>
    </div>
  </div>
</template>

<script>
import { gamenewslist, gamelist } from '@/api/index'
export default {
  data() {
    return {
      id: 0,
      bannerlist: [],
      newsitem: {},
      game_id: 0,
      gameinfo: {},
      showtitle: false
    }
  },
  created() {
    this.id = parseInt(this.$route.query.id)
  },
  mounted() {
    this.getgamenewslist()
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    backclick() {
      this.$router.go(-1)
    },
    handleScroll () {
      let scrollY = document.documentElement.scrollTop
      if (scrollY > 375) {
        this.showtitle = true
      } else {
        this.showtitle = false
      }
    },
    getgamenewslist() {
      gamenewslist({id: this.id }).then(res => {
        const data = res.data[0]
        this.newsitem = data
        this.bannerlist = data.banner_imgurl ? data.banner_imgurl.split(',') : []
        if (this.newsitem.game_id) {
          this.game_id = this.newsitem.game_id
          this.getgamelist()
        }
      })
    },
    getgamelist() {
      gamelist({ _search: 'id', id: this.game_id  }).then(res => {
        this.gameinfo = res.data.data[0]
      })
    }
  }
}
</script>

<style>
.content img {width: 100% !important;height: auto !important;}
.wc-pagination .wc-dot-active {background: #2ED297 !important;}
</style>
<style lang="scss" scoped>
.gamenews {
  .gamenewstop {
    position: relative;
    .backtitle {
      position: fixed;
      right:0;
      top: 0;
      left: 0;
      background: #fff;
      z-index: 100;
      .backinfo {
        height: 70px;
        .back {
          background: #fff;
          .icon {
            color: #000;
            font-weight: 700;
          }
        }
        .backtxt {
          position: absolute;
          left: 68px;
          width: 283px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 70px;
          font-weight: 700;
          color: #000;
        }
      }
      .back {
        position: absolute;
        width: 44px;
        height: 44px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 50%;
        top: 57px;
        left: 24px;
        text-align: center;
        .icon {
          color: #fff;
          font-size: 24px;
          line-height: 44px;
        }
      }
    }
  }
  .gamenewsmain {
    padding:0 20px;
    .title {
      font-size: 24px;
      font-weight: 700;
      margin-top: 21px;
      margin-bottom: 24px;
    }
    .datetime {
      color: #A2A4B4;
      font-size: 14px;
    }
    .relationgame {
      height: 80px;
      background: rgba(46, 210, 151, 0.1);
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding:0 10px;
      margin-top: 24px;
      .left {
        display: flex;
        align-items: center;
        .gamelogourl {width: 60px;height: 60px;border-radius: 12px;margin-right: 16px;}
        .playtxt {font-size: 12px;color: #74A996;line-height: 20px;}
        .nametxt {font-size: 14px;line-height: 20px;}
      }
      .see {color: #00B977;font-size: 14px;}
    }
  }
}
</style>


