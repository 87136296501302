import request from '@/api/request'
// 帮助说明列表
export function helplist(query) {
  return request({
    url: '/admin/helpnotes/helplist',
    method: 'get',
    params: query
  })
}
// 游戏资讯列表
export function gamenewslist(query) {
  return request({
    url: '/admin/gamenews/getnewslist',
    method: 'get',
    params: query
  })
}
// 游戏列表
export function gamelist(query) {
  return request({
    url: '/admin/game/list',
    method: 'get',
    params: query
  })
}
// 获取当前用户的订单和抽奖次数
export function getuserorders(query) {
  return request({
    url: '/admin/prize/getuserorders',
    method: 'get',
    params: query
  })
}
// 当前用户抽中的奖品
export function getuserprize(query) {
  return request({
    url: '/admin/prize/getuserprize',
    method: 'get',
    params: query
  })
}
// 抽奖
export function prizedraw(query) {
  return request({
    url: '/admin/prize/prizedraw',
    method: 'get',
    params: query
  })
}
// 抽奖
export function prizedraw1(query) {
  return request({
    url: '/admin/prize/prizedraw1',
    method: 'get',
    params: query
  })
}
// 填写中奖账号信息
export function editprizelog(query) {
  return request({
    url: '/admin/prizelog/edit',
    method: 'get',
    params: query
  })
}
